<template>
    <div class="login-page">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="mt-5 p-3">
                        <h1>LOGOWANIE</h1>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="my-1 p-3">                        
                        <div class="form-container my-2">
                            <form method="post" @submit.prevent="beforeLogin" action="">

                                <inputEmail v-model="form.email" name="email" label="E-mail" :error="validation.firstError('form.email')" />
                                <inputPassword v-model="form.password" name="password" label="Hasło" :error="validation.firstError('form.password')" />
                                <dismissAlert ref="errorAlert" />

                                <div class="text-center">
                                    <buttonLoader type="submit" class="btnLong" variant="success" :isLoading="inProgress" label="Zaloguj" />
                                </div>
                        
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-container my-2">
                            <div class="hr-sect">lub</div>
                    </div>
                </div>
            </div>
            <div class="row pb-5">
                <div class="col-12 text-center">
                    Nie masz jeszcze konta? 
                    <div class="text-center">                        
                        <b-button variant="secondary" :to="{name: 'Register'}" class="m-3 btnLong">Zarejestruj się</b-button>
                    </div>
                </div>
                <div class="col-12 text-center">
                    Zapomniałeś hasła? <router-link :to="{name: 'ForgotPassword'}" >Wygeneruj nowe</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { USER_typeAdmin, USER_typeDriver, USER_typeCustomer  } from "@/constants/constants.js";

import SimpleVueValidation from 'simple-vue-validator';
const Validator = SimpleVueValidation.Validator;

export default {
    name: "Login",
    data: () => ({
        inProgress: false,
        form: {
            email: null,
            password: null,
        }
    }),
    validators: {
        'form.email': function (value) {
            return Validator.value(value).required().minLength(5).maxLength(255).email();
        },
        'form.password': function (value) {
            return Validator.value(value).required().minLength(8).maxLength(100);
        }
    },
    methods: {
        beforeLogin(){
            this.inProgress = true;
            this.$validate().then( this.login );
        },
        accountLink() {
            switch ( this.$store.getters['auth/getUserType'] ) {
                case USER_typeAdmin: { return {name: 'AdminAccount'}; }
                case USER_typeDriver: { return {name: 'DriverAccount'}; }
                case USER_typeCustomer: { return {name: 'OrderAdd'}; }
                // case USER_typeCustomer: { return {name: 'CustomerAccount'}; }
            }
        },
        login(success){
            if (success) {
                this.$store.dispatch('auth/login', this.form)
                    .then( () => {
                        if ( this.$store.getters['auth/isCustomer'] ) {
                            if ( ! this.$store.state.order.schedule.userId ) {
                                this.$store.dispatch('order/updateUserId', this.$store.state.auth.me.id)
                                    .finally( () => {
                                        this.$router.push( this.accountLink() );
                                    });
                            }
                        } else {
                            this.$router.push( this.accountLink() );
                        }
                    }).catch( error =>  {
                        this.inProgress = false;
                        console.log(error);
                        this.$refs.errorAlert.alertShowDanger('Błąd logowania. Sprawdź czy wpisałeś prawidłowo login i hasło');
                    });
            } else {
                this.inProgress = false;
            }
        }
    }
};
</script>
